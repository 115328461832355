export const DEFAULT_PAGINATION = {
	rowsPerPage: 200,
	page: 1
}
export const DEFAULT_PAGINATION_RISK = {
	rowsPerPage: 500,
	page: 0
}

export const DEFAULT_RAW_DATA = {
	total: 0,
	results: []
}

export const INTEGRATION_RULE_GROUPS = {
	CORTEX: 'paloalto-cortex-xdr',
	OFFICE365: 'office365',
	BITDEFENDER: 'bitdefender-xdr',
	CLOUDFLARE: 'cloudflare-waf',
	PRISMA: 'prisma-cspm',
	SENTINELONE: 'sentinelone',
	ACTIVE_DIRECTORY: 'active-directory-usecases',
	SENTINELONE_AGENT: 'sentinel-one-agent',
	QUALYS: 'qualys',
	PANORAYS: 'panorays',
	INDUSFACE: 'indusface',
	MICROSOFT_SCCM: 'microsoft-sccm',
	CMDB_BMC: 'cmdb',
	PROMISEC_COMPLIANCE_SOLUTION: 'promisec',
	PATCHING_BMC: 'patching-bmc',
	IMPERVA: 'imperva',
	VIRTUAL_PATCHING_SOLUTION: 'virtual-patching-solution',
	MICROFOCUS_ARCSIGHT: 'microfocus',
	FORESCOUT: 'forescoutnac',
	MANAGEENGINE: 'manageengine',
	FORTINET_FORTIGATE: 'fortigate',
	CISCO_AMP: 'cisco-amp',
	GOOGLE_WORKSPACE: 'google-workspace',
	CROWDSTRIKE: 'crowdstrike',
	PINGSAFE: 'pingsafe',
	TRENDMICRO: 'trend_micro',
	SOPHOS_EDR: 'sophos-edr'


}
